<div class="container-fluid">


    <ng-container *ngIf="booking != null; else loading">




    <div class="row">
        <div class="col-xs-8">
            <a routerLink="/bookings/">Alle Buchungen</a>
            <h1>Buchung ({{booking.bookingCode}})</h1>
        </div>
        <div class="col-xs-4 right">
            &nbsp;<br>
            <mat-form-field appearance="outline">
                <mat-select [(value)]="booking.status" (selectionChange)="toggleStatus($event)" [disabled]="booking.status != 'pending'">
                    <mat-option [value]="'pending'">Offen</mat-option>
                    <mat-option [value]="'booked'" [disabled]="true">Gebucht</mat-option>
                    <mat-option [value]="'booked_shipped'">Gebucht (ohne digitale Tickets)</mat-option>
                    <mat-option [value]="'not_bookable'">Nicht buchbar</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
           
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">            
            <h2>Veranstaltung</h2>

                <div class="box">

                <div class="row">
                    <div class="col-xs-12">
                        <p><strong>{{booking?.eventSummary?.name}}</strong></p>
                        <hr>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4">      
                        <p><small>Zeit:</small> <br>{{booking.showtime?.datetime | date: 'dd.MM.yyyy | HH:mm'}}&nbsp;Uhr</p>
                    </div>
                    <div class="col-xs-4">      
                        <p><small>Location:</small> <br>{{booking?.showtime?.location?.venue}}, {{booking?.showtime?.location?.street}}, {{booking?.showtime?.location?.zip}} {{booking?.showtime?.location?.city}}</p>
                    </div>
                    <div class="col-xs-4">
                        <span class="right">
                            <ng-container *ngIf="booking.showtime?.channel == 'muenchenticket'"><img src="https://www.muenchenticket.de/assets/content/default/mt-logo_komplett.svg" height="50px"></ng-container>
                        </span>
                    </div>
                </div>
            </div>

            <h2>Tickets</h2>

            <div class="box">

            <table class="contentTable">
                <tr>
                    <th></th>
                    <th>Ticket-ID</th>
                    <th>Typ</th>
                    <th>Kategorie</th>
                    <th>Preis</th>
                    <th *ngIf="booking?.status == 'pending' && booking?.shipping?.method != 'shipping_by_post'">PDF Upload</th>
                </tr>

                <ng-container *ngFor="let ticket of booking.tickets; let i = index">


                <tr>
                    <td>{{i+1}}</td>
                    <td>{{ticket?.ticketNr}}</td>
                    <td>{{ticket?.priceCategory?.name}}</td>
                    <td>{{ticket?.priceCategory?.category}}
                        <ng-container *ngFor="let seat of ticket.seats"><br><small><span *ngIf="seat.area">{{seat.area}}, </span>Reihe: {{seat.rowNumber}}, Sitz: {{seat.seatNumber}}</small></ng-container>
                    </td>

                    <td>
                        <ng-container *ngIf="ticket?.priceCategory?.specialPrice"><strong>Spezialpreis: {{ticket?.priceCategory?.specialPrice.coins}} credits</strong><br/></ng-container>
                        {{ticket?.priceCategory?.amount | number: '1.2'}}&nbsp;€<br><small>({{ticket?.priceCategory?.coins}}&nbsp;credits)</small>
                    </td>
            
                    <td *ngIf="booking?.status == 'pending' && booking?.shipping?.method != 'shipping_by_post'">
                        <input class="ng-hide" id="ticketFile-{{i+1}}" (change)="onFileSelected($event, ticket?.ticketNr)" type="file" />
                    </td>
                </tr>
                </ng-container>

                <!-- <tr>

                    <td colspan="4"><span class="right">Versand:</span></td>
                    <td><strong class="right">5,00 €</strong></td>

                </tr> -->

                <!-- <tr>

                    <td colspan="4"><strong class="right">Gesamt:</strong></td>
                    <td><strong class="right">{{booking.total}} €</strong></td>

                </tr> -->
                <tr>
                    <td colspan="5">
                        <span class="right">
                            <div class="red">
                                {{errorMessage}}

                            </div>
                        </span>

                    </td>
                    <td *ngIf="booking?.shipping?.method == 'shipping_by_post'">
                        <span class="right">Versandart: <strong>Post-Zustellung</strong></span>
                    </td>

                    <td *ngIf="booking?.status == 'pending' && booking?.shipping?.method != 'shipping_by_post'">
                        <button mat-flat-button color="primary" class="full-width" (click)="uploadTickets()">Tickets hochladen</button>
                    </td>
                </tr>
            </table>
   
            </div>

            <h2>Kunden-Informationen</h2>

            <div class="box">
                <span class="center">
                    <p>UID: {{booking.uid}}</p>
                    <button mat-flat-button (click)="getCustomer()">Kunden-Informationen anzeigen</button>
                </span>
                <ng-template>

                </ng-template>
            </div>
            <!-- <div class="row">
                <div class="col-xs-12 col-md-6">
                    <h2>Zustellung</h2>

                    <div class="box">
                        <ng-container *ngIf="booking.shipping == 0">
                            <mat-icon>local_shipping</mat-icon><br>
                            Standard Versand
                        </ng-container>
                        <ng-container *ngIf="booking.shipping == 1">
                            <mat-icon>directions_bike</mat-icon><br>
                            Express Versand                            
                        </ng-container>
                        <ng-container *ngIf="booking.shipping == 2">
                            <mat-icon>store_mall_directory</mat-icon><br>
                            Abholung im Geschäft                           
                        </ng-container>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6">
                    <h2>Lieferadresse</h2>

                    <div class="card">
                    
                        <div class="row verticalMiddle">
                            <div class="col-xs-10">
                                <strong>{{booking.shippingAddress.firstName}} {{booking.shippingAddress.lastName}}</strong><br>
                                {{booking.shippingAddress.street}}<br>
                                {{booking.shippingAddress.zip}} {{booking.shippingAddress.city}}<br>
                                <ng-container *ngIf="booking.shippingAddress.country == 'DE'">Deutschland</ng-container>
                            </div>
                            <div class="col-xs-2">
                                <button mat-icon-button aria-label="Adresse kopieren" (click)="copyShippingAddress()" class="right">
                                    <mat-icon>content_copy</mat-icon>
                                </button>
                            </div>
                        </div>
             
                     
                    </div>
              
                </div>
            </div> -->

                

               

            
        </div>
        <!-- <div class="col-xs-12 col-md-3">
            <h2>Status</h2>
            <mat-form-field appearance="outline">
                <mat-select [(value)]="booking.status" (selectionChange)="toggleStatus($event)">
                    <mat-option [value]="100">Offen</mat-option>
                    <mat-option [value]="500">Gebucht</mat-option>
                    <mat-option [value]="600">Storniert</mat-option>
                  </mat-select>
              </mat-form-field>
            

        </div> -->
    </div>


</ng-container>

<ng-template #loading>
    LOADING
</ng-template>


</div>

<!-- ZUSTELLOPTION

ABHOLUNG
EINSCHREIBEN
EXPRESS-KURIER -->
