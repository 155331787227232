       
<header  mat-dialog-header>
    <h2>Status setzen <mat-icon class="right" mat-dialog-close>close</mat-icon></h2>
</header>
<section mat-dialog-content>
    <p>Bist du sicher das du den Status der Buchung auf <strong>{{data.status}}</strong> setzen möchtest?</p>
</section>
<footer mat-dialog-actions>
    <button mat-flat-button color="secondary" mat-dialog-close>Abbrechen</button>
    <button mat-flat-button color="primary" (click)="closeDialog()">Status setzen</button>
</footer>