<div class="container-fluid">
    <ng-container *ngIf="event != null; else loading">
        <div class="row verticalMiddle">
            <div class="col-xs-8">
                <a routerLink="/content/events" class="verticalMiddle"><mat-icon>keyboard_arrow_left</mat-icon> Alle Events</a>
                <h1>{{event?.data.name}}</h1>
            </div>
            <div class="col-xs-4"> 
                <span class="right mt-4 verticalMiddle">
                    <mat-icon *ngIf="checkForUpdate('visible')"  color="primary" matSuffix>update</mat-icon>
                    &nbsp;&nbsp;
                    <mat-button-toggle-group name="visibility" (change)="switchVisibility(event.id, $event.value)" aria-label="Sichtbarkeit" value="{{visibility}}">
                        <mat-button-toggle value="visibility_on"><mat-icon>visibility_on</mat-icon></mat-button-toggle>
                        <mat-button-toggle value="visibility_off"><mat-icon [class.red] = "!event?.data.visible">visibility_off</mat-icon></mat-button-toggle>
                    </mat-button-toggle-group>

                </span>

        </div>
        </div>
        <div class="row">
            <div class="col-xs-12">    
                
                <mat-tab-group>
                    <mat-tab label="Details">


                        <div class="box mt-4">
            
                            <form [formGroup]="eventDetailForm" (ngSubmit)="updateEvent(event.id)">
                                <div class="row">
                                    <div class="col-xs-6">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Typ</mat-label>
                                            <mat-select formControlName="type" #type>
                                                <mat-option [value]="'unknown'" [disabled]="true">Unbekannt</mat-option>
                                                <mat-option [value]="'music'">Konzert</mat-option>
                                                <mat-option [value]="'movie'">Kino</mat-option>
                                                <mat-option [value]="'theater'">Theater</mat-option>
                                                <mat-option [value]="'exhibition'">Museum</mat-option>
                                                <!-- <mat-option [value]="'sport'">Sport</mat-option>
                                                <mat-option [value]="'outdoor'">Outdoor</mat-option> -->
                                            </mat-select>                                    
                                            <mat-icon *ngIf="checkForUpdate('type')" color="primary" matSuffix>update</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Name</mat-label>
                                            <input matInput type="text" tabindex="1" formControlName="name" #name>
                                            <mat-icon *ngIf="checkForUpdate('name')" color="primary" matSuffix>update</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Popularity</mat-label>
                                            <input matInput type="text" tabindex="3" formControlName="popularity" #popularity >
                                            <mat-icon *ngIf="checkForUpdate('popularity')" color="primary" matSuffix>update</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Beschreibung</mat-label>
                                            <textarea  tabindex="4" formControlName="description" #description matInput   cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="5"
                                            cdkAutosizeMaxRows="20"></textarea>
                                            <mat-icon *ngIf="checkForUpdate('description')" color="primary" matSuffix>update</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12">
                                        <h2>Bilder</h2>
                                    </div>
                                </div>
                                <div class="box">
                                    <div class="row">
                                        <div class="col-xs-9">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>Thumbnail-URL</mat-label>
                                                <input matInput type="text" tabindex="2" formControlName="thumbnail" #thumbnail >
                                                <mat-icon *ngIf="checkForUpdate('thumbnail')" color="primary" matSuffix>update</mat-icon>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-xs-3">
                                            <img src="{{thumbnail.value}}" width="100%">
                                        </div>
                                    </div>
                                </div>
                      
                                <div class="row">
                                    <div class="col-xs-12">
                                        <button mat-flat-button color="primary" tabindex="5">Speichern</button>
                                    </div>
                                </div>
                            </form>

                        </div>   


                    </mat-tab>
                    <mat-tab label="Showtimes"> 


                  
                        <ng-container *ngIf="!loadingState; else loadinShowtimes">
                            <ng-container *ngFor="let showtime of showtimes">

                        <div class="box mt-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <h2>{{showtime.datetime | date: 'dd.MM.yyyy | HH:mm'}} Uhr <span class="right verticalMiddle">{{showtime.status}} &nbsp;|&nbsp; <mat-icon class="red" *ngIf="!event?.data.visible">visibility_off</mat-icon><mat-icon *ngIf="event?.data.visible">visibility_on</mat-icon></span></h2>        
                                    </div>
                                </div>

                                <ng-container *ngFor="let priceCategory of showtime.priceCategories, let i = index">
                                    <hr>                
                                    <div class="row verticalMiddle">
                                        <div class="col-xs-3">
                                            {{priceCategory.category}} | {{priceCategory.name}}
                                        </div>
                                        <div class="col-xs-2">
                                            {{priceCategory.amount | number: '1.2'}} €
                                        </div>
                                        <div class="col-xs-2">
                                            <strong>{{priceCategory.coins}} Credits</strong>
                                        </div>
                                        <div class="col-xs-5 ">
                                            <ng-container *ngIf="!priceCategory.specialPrice; else specialPriceExists">
                                                <form [formGroup]="priceCategoryForm" (ngSubmit)="updatePriceCategory(showtime, i, priceCategory)">
                                                    <mat-form-field  appearance="outline">
                                                        <mat-label>Spezialpreis</mat-label>
                                                        <input matInput type="number" formControlName="coins" #coins>
                                                        <mat-hint>credits</mat-hint>
                                                    </mat-form-field> &nbsp;
                                                    <mat-form-field  appearance="outline">
                                                        <mat-label>Beschreibung</mat-label>
                                                        <input matInput type="text" maxlength="15" formControlName="description" #description>
                                                        <mat-hint align="end">{{description.value.length}} / 15</mat-hint>
                                                    </mat-form-field> &nbsp;
                                                    <button mat-flat-button color="primary">Speichern</button>
                                                </form>
                                            </ng-container>
                                            <ng-template #specialPriceExists>                                                 
                                                <a (click)="removeSpecialPrice(showtime, i, priceCategory)" class="right">entfernen</a>
                                                <strong>Spezialpreis: {{priceCategory.specialPrice.coins}} Credits</strong><br>
                                                {{priceCategory.specialPrice.description}}
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-container>
      
                            </div>
        
                        </div>      
                    </ng-container>  
                </ng-container>  
                    
                    <ng-template #loadinShowtimes>
                        <div class="box mt-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-xs-12">
                        <mat-spinner></mat-spinner>
                                    </div></div></div></div>
                    </ng-template>

                    </mat-tab>
                </mat-tab-group>



            </div>
        </div>
    </ng-container>
    <ng-template #loading>
        LOADING
    </ng-template>
</div>
