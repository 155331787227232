// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyDlgfQTcGy3BYMOuad5smYPyPLwNtzjfAk",
//     authDomain: "myeventpass-staging.firebaseapp.com",
//     databaseURL: "https://myeventpass-staging.firebaseio.com",
//     projectId: "myeventpass-staging",
//     storageBucket: "myeventpass-staging.appspot.com",
//     messagingSenderId: "94788347208",
//     appId: "1:94788347208:web:7f918b443998274d60ff7d"
//   },
//   mapbox: {
//     accessToken: 'pk.eyJ1IjoibXlldmVudHBhc3MiLCJhIjoiY2tudTIwc3M3MDhtdjJwbmpmNDdqazM3aiJ9.ULr6JI1bUMI-ViXyQJlZ5Q'
//   },
// };

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAg9NpgY_U1CbrvxgjUHAV2Sd1VnIFjVtk",
    authDomain: "myeventpass-prod.firebaseapp.com",
    databaseURL: "https://myeventpass-prod.firebaseio.com",
    projectId: "myeventpass-prod",
    storageBucket: "myeventpass-prod.appspot.com",
    messagingSenderId: "550030894187",
    appId: "1:550030894187:web:f936af5fb98784d74abd2e",
    measurementId: "G-0JGX9DZ1HB"
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoibXlldmVudHBhc3MiLCJhIjoiY2tudTIwc3M3MDhtdjJwbmpmNDdqazM3aiJ9.ULr6JI1bUMI-ViXyQJlZ5Q'
  },
};
