import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { LocationService } from 'src/app/services/location.service';

export interface EventData {
  id: string;
  type: string;
  name: string;
  mostRecentShowtime:string;
  visibleNewAt:string;
  popularity:number;
  visible:boolean;
}

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss']
})
export class ContentListComponent implements AfterViewInit {
  eventsTableColumns: string[] = ['id', 'name', 'type', 'mostRecentShowtime', 'visibleNewAt', 'popularity', 'visible'];
  locationsTableColumns: string[] = ['id', 'venue', 'type', 'visible'];
  eventsDataSource: MatTableDataSource<EventData>;
  locationsDataSource: MatTableDataSource<EventData>;
  filterKeyword: string = "";
  events:any;
  activeTabIndex:number = 0;
  allEvents:boolean;
  filterValues = {
    search: '',
    visibility: '',
  };

  @ViewChild(MatSort) eventsSort: MatSort;
  @ViewChild(MatSort) locationsSort: MatSort;

  constructor(
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService
  ) {
    let paramTab = this.activatedRoute.snapshot.url[1].path;
    if(paramTab == "locations") {
      this.activeTabIndex = 1;
    } 
    this.getEvents();
    // this.getShowtimes();
    this.getLocations();
  }

  getSelectedIndex(): number {
    return this.activeTabIndex
  }

  onTabChange(event: MatTabChangeEvent) {
      this.activeTabIndex = event.index
  }


  // getShowtimes() {      




  //   this.eventService.getAllShowtimes().subscribe((result:any) => {
  //     if (result) {
  //       result.forEach((element:any) => {
  //         console.log(element);

            // this.eventService.deleteSubCollectionFile(element.parentid, element.id);
  //       });
  //     }

  //   });
  // }
  

  getEvents() {
    this.eventService.getEvents().subscribe((result:EventData) => {
      this.events = result;


      if(result) {
        this.changeTypeUnknownToMusic(result);
      }

      // if(result) {
      //   this.deleteFiles(result);
      // }
      this.eventsDataSource = new MatTableDataSource(this.events);
      this.eventsDataSource.filterPredicate = (data, filter) => {
        let filterValues = JSON.parse(filter);
        let searchFilter = true;
        let visibilityFilter: boolean = filterValues["visibility"] === "true";
        let idFilter = data?.id?.indexOf(filterValues["search"]) != -1;
        let nameFilter = data?.name?.indexOf(filterValues["search"]) != -1;
        let typeFilter = data?.type?.indexOf(filterValues["search"]) != -1;
        if (idFilter || nameFilter || typeFilter ) {
            searchFilter = true;          
        } else {
          searchFilter = false;
        } 
        if (visibilityFilter) {
          if (data.visible === visibilityFilter && searchFilter) {
            return true
          } else {
            return false
          }
        } else {
          return searchFilter
        }
        return false
      }

      this.eventsDataSource.sort = this.eventsSort;
      let cachedFilter = this.getLocalStorageFilter();
      let cachedSort = this.getLocalStorageSort();
      let cachedSortDirection = this.getLocalStorageSortDirection();
      if(cachedFilter) {
        this.applyFilter(cachedFilter);
        let filterValues = JSON.parse(cachedFilter);

        this.filterValues['search'] = filterValues['search'];
        this.filterValues['visibility'] = filterValues['visibility'];
        this.filterKeyword = filterValues['search'];
        if (filterValues['visibility'] === "true") {
          this.allEvents = true;
        }
      }
      if(cachedSort && cachedSortDirection) {
        this.applySort(cachedSort,cachedSortDirection);
      }
    });
  }


  // deleteFiles(events:any) {
  //   events.forEach((element:any) => {
  //     if(element.manuallyUpdated?.length > 4) {
  //       console.log(element);
  //       let array = element.manuallyUpdated;
  //       array.length=2;

  //       console.log(array);
  //       this.eventService.updatemanuallyupdate(array, element.id);



  //     }

  //     // console.log(element.id);
  //   });
  // }

  getLocations() {
    this.locationService.getLocations().subscribe((result:any) => {
      if(result.length > 0) {
        this.locationsDataSource = new MatTableDataSource(result);
      }
    });
  }

  getLocalStorageFilter() {
    return localStorage.getItem('filter');
  }

  getLocalStorageSort() {
    return localStorage.getItem('eventsSort');
  }

  getLocalStorageSortDirection() {
    return localStorage.getItem('sortDirection');
  }

  ngAfterViewInit() {}

  addSearchFilter(value:string) {
    this.filterValues['search'] = value;
    this.applyFilter(JSON.stringify(this.filterValues));
  }

  addVisibilityFilter(value:boolean) {
    this.filterValues['visibility'] = value.toString();
    this.applyFilter(JSON.stringify(this.filterValues));
  }

  applyFilter(filterValue: string) {
    localStorage.setItem('filter', filterValue);
    this.eventsDataSource.filter = filterValue.trim().toLowerCase();
  }
  
  changeTypeUnknownToMusic(unknownEvents:any) {
    unknownEvents.forEach((element:any) => {
      // if(element.type === 'unknown') {
      //   var updateList = {};
      //   Object.assign(updateList, {'type':'music'});
      //   this.eventService.updateEvent(
      //     element.id, updateList
      //   ).subscribe(result => {
      //     console.log(result);
      //   });
      // }

      });
    }


 // applySearchFilter(value:string) {
  //   this.filterValues['search'] = value.trim().toLowerCase();
  //   // this.eventsDataSource.filter = JSON.stringify(this.filterValues);
  //   // this.eventsDataSource.filter = value.toString().trim().toLowerCase();

  //   let filterString = this.filterValues.search.toString() + "|" + this.filterValues.visibility.toString();
  //   this.eventsDataSource.filter = filterString;

  //   console.log(this.eventsDataSource.filter);
  // }

  // applyVisibilityFilter(value:boolean) {
  //       // this.eventsDataSource.filter = value.toString().trim().toLowerCase();

  //   // this.filterValues['visibility'] = value.toString();

  //   let filterString = this.filterValues.search.toString() + "|" + this.filterValues.visibility.toString();
  //   this.eventsDataSource.filter = filterString;

  // }


  // applyFilter(filterValue: string) {

  // this.eventsDataSource.filterPredicate = (data, filterValue: string): boolean => {
  //   let searchString = JSON.parse(filterValue);
  //   let isVisible = false;
  //   if (searchString.position.length) {
  //     for (const d of searchString.position) {
  //       if (data.position.trim() === d) {
  //         isPositionAvailable = true;
  //       }
  //     }
  //   } else {
  //     isPositionAvailable = true;
  //   }
  //   const resultValue = isPositionAvailable &&
  //     data.name.toString().trim().toLowerCase().indexOf(searchString.name.toLowerCase()) !== -1 &&
  //     data.email.toString().trim().toLowerCase().indexOf(searchString.email.toLowerCase()) !== -1 &&
  //     data.status.toString().trim().toLowerCase().indexOf(searchString.status.toLowerCase()) !== -1;
      
  //   return resultValue;

  // }
  // this.dataSource.filter = JSON.stringify(this.filterValues);
// }


  switchVisibility() {
    this.allEvents = !this.allEvents;
  }

  announceSortChange(sortState: Sort) {
    localStorage.setItem('eventsSort',sortState.active);
    localStorage.setItem('sortDirection',sortState.direction);
  }

  applySort(cachedSort:any,cachedSortDirection:any) {
    this.eventsSort.active = cachedSort;
    this.eventsSort.direction = cachedSortDirection;
    this.eventsDataSource.sort = this.eventsSort;
  }

}