import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, of } from 'rxjs';
import { User } from '../models/user.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthService {

  user$: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private db: AngularFirestore
  ){
    this.user$ = this.afAuth.authState.pipe(map(auth=>auth));
  }

  async signInEmail(value:any){
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(value.email, value.password)
      .then(
        res => resolve(res), 
        err => reject(err)
      )
    })
  }

  async signOut() {
    await this.afAuth.signOut();
  }

  // async getUserData(customerId:string) {
  //   const userDocument = await this.db
  //     .collection('user')
  //     .doc(customerId)
  //     .get();
  //   return userDocument;
  // }
  getUserData(document:string) {
    return this.db
    .collection("users")
    .doc(document)
    .collection("transactions")
    .snapshotChanges()
    .pipe(take(1),
      map(actions => actions.map((a:any) => {
        return { 
          id: a.payload.doc.id,
          data: a.payload.doc.data()
        };      
      }))
    );
  }



}