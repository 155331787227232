import { Component } from '@angular/core';
import { BookingsService } from 'src/app/services/bookings.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent {

  closedBookings: Array<any> = [];

  constructor(
    private bookingService: BookingsService
  ) { 
    this.getClosedBookings();
  }

  getClosedBookings() {
    this.bookingService.getClosedBookings().subscribe((result:any) => {
      this.closedBookings = result;
      console.log(result);
    });
  }
  
}
