import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainnavigation',
  templateUrl: './mainnavigation.component.html',
  styleUrls: ['./mainnavigation.component.scss']
})
export class MainnavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
