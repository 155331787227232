import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {

  userId="EU81PXLiExQwEaLLIgeOKQ40vqU2";
  transactions: Array<any> = [];

  constructor(
    private authService: AuthService,
    private eventService: EventService
  ) { 
    this.getUserData(this.userId);
  }

  getUserData(userId:string) {
    console.log(userId);
    // this.eventService.assignCoins(userId).subscribe((result:any) => {console.log(result)});
    this.authService.getUserData(userId).subscribe((result:any) => {
      this.transactions = result;
      console.log(result);
    });
  }
}
