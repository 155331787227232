import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {

  constructor(    
    private db: AngularFirestore,
    private fns: AngularFireFunctions
    ) { }


  // GET BOOKINGS
  getOpenBookings() {
    return this.db.collectionGroup("bookings",ref => ref.where("status","==","pending").orderBy('showtime.datetime','asc')).snapshotChanges().pipe(
      map(actions => actions.map((a:any) => {
        return {
          id:a.payload.doc.id,
          data:a.payload.doc.data()
        };
      }))
    );
  }

  getClosedBookings() {

    // return this.db.collectionGroup("bookings",ref => ref.where("status","!=","pending").orderBy('status').orderBy('showtime.datetime','desc')).snapshotChanges().pipe(
      return this.db.collectionGroup("bookings",ref => ref.where("status","in",["booked", "not_bookable", "booked_shipped"]).orderBy('showtime.datetime','desc')).snapshotChanges().pipe(
        map(actions => actions.map((a:any) => {
        return {
          id:a.payload.doc.id,
          data:a.payload.doc.data()
        };
      }))
    );
  }

  getBooking(reference:any) {
    return new Promise<any>((resolve, reject) => {
      this.db.collectionGroup("bookings",ref => ref.where("bookingCode","==",reference)).get().subscribe(snapshot => {
        resolve(snapshot.docs[0]);
      }, err => {
        reject(err);
      });
    })
  }


  // UPLOAD BOOKING PDF
  uploadFileToStorage(customerId:any, bookingId:any, ticketNr:string, file:any) {
    const storage = getStorage();
    const storageRef = ref(storage, "tickets/"+customerId+"/"+bookingId+"/"+ticketNr+".pdf");
    const metadata = {
      contentType: 'application/pdf',
      customMetadata: {
        'customerId': customerId,
        'bookingId': bookingId,
        'ticketNr': ticketNr
      }
    };
    uploadBytes(storageRef, file, metadata).then((snapshot) => {
      console.log(snapshot);
    });
  }
 

  // GET CUSTOMER
  async getCustomer(customerId:string) {
    const userDocument = await this.db
      .collection('user')
      .doc(customerId)
      .get();
    return userDocument;
  }


  // SET BOOKING STATUS
  changeBookingStatus(customerId:string, bookingId:string,status:string) {
    const changeBookingStatus = this.fns.httpsCallable('changeBookingStatus'); 
    return changeBookingStatus({
      "customerId": customerId,
      "bookingId": bookingId,
      "newStatus": status
    }).toPromise();
  }


  // getMovieBookings() {
  //   return this.db.collectionGroup("bookings",ref => ref.where("eventSummary.type","==","movie")).snapshotChanges().pipe(
  //     map(actions => actions.map((a:any) => {
  //       return {
  //         id:a.payload.doc.id,
  //         data:a.payload.doc.data()
  //       };
  //     }))
  //   );
  // }

}


