import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.auth.user$.pipe(
        take(1),
        map(user => !!user),
        tap(isLoggedin => {
          if (!isLoggedin) {          
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
          }
      })
    )
  }

}