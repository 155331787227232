import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { MainpageComponent } from './layouts/mainpage/mainpage.component';
import { ModalpageComponent } from './layouts/modalpage/modalpage.component';
import { SubpageComponent } from './layouts/subpage/subpage.component';
import { BookingsComponent } from './views/bookings/bookings.component';
import { DetailComponent } from './views/bookings/detail/detail.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { SignOutComponent } from './views/sign-out/sign-out.component';
import { ContentListComponent } from './views/content/content-list/content-list.component';
import { EventDetailComponent } from './views/content/event-detail/event-detail.component';
import { LocationDetailComponent } from './views/content/location-detail/location-detail.component';
import { StatsComponent } from './views/stats/stats.component';
import { UserListComponent } from './views/users/user-list/user-list.component';

const routes: Routes = [
  {
    path: '', 
    component: MainpageComponent,
    children: [  
      { path: '',   redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard]},
      { path: 'stats', component: StatsComponent, canActivate:[AuthGuard]},
      { path: 'bookings', component: BookingsComponent, canActivate:[AuthGuard]},
      { path: 'bookings/:id', component: DetailComponent, canActivate:[AuthGuard]},
      { path: 'user/:id', component: UserListComponent, canActivate:[AuthGuard]},
      { path: 'content', redirectTo: '/content/events', pathMatch:"full"},

      { path: 'content/events', component: ContentListComponent, canActivate:[AuthGuard]},
      { path: 'content/locations', component: ContentListComponent, canActivate:[AuthGuard]},
      { path: 'content/events/:id', component: EventDetailComponent, canActivate:[AuthGuard]},
      { path: 'content/locations/:id', component: LocationDetailComponent, canActivate:[AuthGuard]}
    ]
  },
  {
    path: '', 
    component: ModalpageComponent,
    children: [  
      { path: 'login', component: SignInComponent},
      { path: 'logout', component: SignOutComponent},
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }