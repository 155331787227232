import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BookingsService } from 'src/app/services/bookings.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  openBookings:any;
  closedBookings:any;
  today: Date = new Date(Date.now());
  tomorrow: Date  = new Date(this.today.setDate(this.today.getDate() + 1));
  tomorrowSeconds = this.tomorrow.getTime() / 1000;

  constructor(
    private bookingService: BookingsService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getBookings();
    // this.getMovieBookings();
  }

  getBookings() {
    this.bookingService.getOpenBookings().subscribe((result:any) => {
      this.openBookings = result;
    });
    this.bookingService.getClosedBookings().subscribe((result:any) => {
      this.closedBookings = result;
      console.log(result);
    });
  }

  exportBookings() {
    this.router.navigate(["/stats"]);
    // const dialogRef = this.dialog.open(ExportBookingsComponent, {
    //   width: '400px',
    //   data: {bookings: this.closedBookings},
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     console.log(result);
    //   }
    // });
  }


  // getMovieBookings() {

  //   let exportData: Array<any> = [];

  //   this.bookingService.getMovieBookings().subscribe((result:any) => {
  //     result.forEach((element:any) => {

  //       if (!element.data.channelResult.resultObject) {
  //         exportData.push(
  //           {
  //             bookingCode: element.data.bookingCode,
  //             tickets: element.data.channelResult
  //           }
  //         );
  //       }

    

  //     });

  //     console.log(result);
  //     console.log(exportData);


  //   });
  // }

}


// @Component({
//   selector: 'app-export-bookings',
//   templateUrl: './export-bookings.component.html',
//   styleUrls: ['./export-bookings.component.scss']
// })
// export class ExportBookingsComponent implements OnInit {
  
//   constructor(
//     public dialogRef: MatDialogRef<ExportBookingsComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any
//   ) {}

//   ngOnInit(): void {
//     console.log(this.data);
//   }

//   export() {

//   }

//   closeDialog() {
//     this.dialogRef.close(true);
//   }

// }