<p>User {{userId}}</p>

<table width="100%">
<tr>
    <td>ID</td>
    <td>Datum</td>
    <td>Verfallsdatum</td>
    <td>Typ</td>
    <td>Credits</td>
</tr>


<ng-container *ngFor="let transaction of transactions, let i=index"> 
    <tr>
        <td>{{i}}</td>
        <td>{{transaction.data.creationDate | date : 'dd.MM.yyyy' }}</td>
        <td>{{transaction.data.creationDate | date : 'dd.MM.yyyy'}}</td>
        <td>{{transaction.data.creditType }}</td>
        <td>{{transaction.data.creditValue }}</td>
    </tr>
</ng-container>

</table>