<div class="container-fluid">
    <ng-container *ngIf="location != null; else loading">
        <div class="row verticalMiddle">
            <div class="col-xs-8">
                <a routerLink="/content/locations" class="verticalMiddle"><mat-icon>keyboard_arrow_left</mat-icon> Alle Locations</a>
                <h1>{{location?.data.venue}}</h1>  
            </div>
            <div class="col-xs-4"> 
                <span class="right mt-4 verticalMiddle">
                    <mat-icon *ngIf="checkForUpdate('visible')"  color="primary" matSuffix>update</mat-icon>
                    &nbsp;&nbsp;
                    <mat-button-toggle-group name="visibility" (change)="switchVisibility(location.id, $event.value)" aria-label="Sichtbarkeit" value="{{visibility}}">
                        <mat-button-toggle value="visibility_on"><mat-icon>visibility_on</mat-icon></mat-button-toggle>
                        <mat-button-toggle value="visibility_off"><mat-icon [class.red] = "!location?.data.visible">visibility_off</mat-icon></mat-button-toggle>
                    </mat-button-toggle-group>

                </span>

        </div>
        </div>
        <div class="row">
            <div class="col-xs-12">    
                
                <mat-tab-group  (selectedTabChange)="initMap(location)">
                    <mat-tab label="Details">


                        <div class="box mt-4">
            
                            <form [formGroup]="locationDetailForm" (ngSubmit)="updateLocation(location.id)">
                                <div class="row">
                                    <div class="col-xs-6">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Typ</mat-label>
                                            <mat-select formControlName="type" #type>
                                                <mat-option [value]="'unknown'" [disabled]="true">Unbekannt</mat-option>
                                                <mat-option [value]="'music'">Konzert</mat-option>
                                                <mat-option [value]="'movie'">Kino</mat-option>
                                                <mat-option [value]="'theater'">Theater</mat-option>
                                                <mat-option [value]="'exhibition'">Museum</mat-option>
                                                <!-- <mat-option [value]="'sport'">Sport</mat-option>
                                                <mat-option [value]="'outdoor'">Outdoor</mat-option> -->
                                            </mat-select>                                    
                                            <mat-icon *ngIf="checkForUpdate('type')" color="primary" matSuffix>update</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row verticalMiddle">
                                    <div class="col-xs-6">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Venue</mat-label>
                                            <input matInput type="text" tabindex="1" formControlName="venue" #venue>
                                            <mat-icon *ngIf="checkForUpdate('venue')" color="primary" matSuffix>update</mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-xs-6" *ngIf="location?.data?.name">
                                        <p class="small">Name: {{location?.data?.name}}</p>                            

                                    </div>
                                </div>
                                
                      
                                <div class="row">
                                    <div class="col-xs-12">
                                        <button mat-flat-button color="primary" tabindex="5">Speichern</button>
                                    </div>
                                </div>
                            </form>

                        </div>   


                    </mat-tab>
                    <mat-tab label="Geolocation">
                        <div class="box mt-4">
                            <div id="mapContainer" class="mapContainer"></div>
                        </div>

                    </mat-tab>
                </mat-tab-group>



            </div>                        
        </div>    

    </ng-container>
    <ng-template #loading>
        LOADING
    </ng-template>
</div>
