<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
            <h1>Daten Management</h1>
        </div>
      </div>

      <mat-tab-group [selectedIndex]="activeTabIndex">
        <mat-tab label="Events">

          <div class="box mt-4">
      <div class="row">
        <div class="col-xs-4">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Filtern</mat-label>
            <input matInput (keyup)="addSearchFilter(input.value)" placeholder="Name, Typ, …" [value]="filterKeyword" #input>
            <button *ngIf="input.value" (click)="input.value=''; addSearchFilter('')" mat-icon-button matSuffix [attr.aria-label]="'löschen'" >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-xs-8">
          <span class="right">
            <!-- <mat-button-toggle-group name="allEvents" (change)="addVisibilityFilter(true)" aria-label="Sichtbarkeit" value="{{allEvents}}">
              <mat-button-toggle value=""> Alle Events</mat-button-toggle>
              <mat-button-toggle value="false">Nur Sichtbare Events</mat-button-toggle>
            </mat-button-toggle-group> -->
            <mat-checkbox (change)="addVisibilityFilter($event.checked)" [checked]="allEvents">Nur sichtbare Events</mat-checkbox>

          </span>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">

              
              <div >
                <table mat-table [dataSource]="eventsDataSource" (matSortChange)="announceSortChange($event)" class="full-width" matSort>
              
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="30%"> ID </th>
                    <td mat-cell *matCellDef="let row"><a routerLink="/content/events/{{row.id}}">{{row.id}}</a></td>
                  </ng-container>

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let row"><a routerLink="/content/events/{{row.id}}">{{row.name}}</a></td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Typ </th>
                    <td mat-cell *matCellDef="let row"> {{row.type}} </td>
                  </ng-container>

                  <ng-container matColumnDef="mostRecentShowtime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%"> Nächste Showtime </th>
                    <td mat-cell *matCellDef="let row">{{row.mostRecentShowtime | date:'dd.MM.yyyy | HH:mm'}}</td>
                  </ng-container>

                  <ng-container matColumnDef="visibleNewAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%"> Erstmals sichtbar </th>
                    <td mat-cell *matCellDef="let row">{{row.visibleNewAt | date:'dd.MM.yyyy'}}</td>
                  </ng-container>

                  <ng-container matColumnDef="popularity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Popularity </th>
                    <td mat-cell *matCellDef="let row">{{row.popularity}}</td>
                  </ng-container>
                  
                  <ng-container matColumnDef="visible">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Visible </th>
                    <td mat-cell *matCellDef="let row"><mat-icon *ngIf="row.visible == false" class="red">visibility_off</mat-icon></td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="eventsTableColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: eventsTableColumns;"></tr>
              
                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">Keine passenden Ergebnisse für "{{input.value}}" gefunden.</td>
                  </tr>
                </table>
              
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
              </div>
              
          </div>
        </div>
      </div>
    </mat-tab>
        <mat-tab label="Locations">
          <div class="box mt-4">
          <div class="row">

            <div class="col-xs-12">
              








              <table mat-table [dataSource]="locationsDataSource" class="full-width" >
              
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef  width="30%"> ID </th>
                  <td mat-cell *matCellDef="let row"><a routerLink="/content/locations/{{row.id}}">{{row.id}}</a></td>
                </ng-container>

                <ng-container matColumnDef="venue">
                  <th mat-header-cell *matHeaderCellDef> Venue </th>
                  <td mat-cell *matCellDef="let row"><a routerLink="/content/locations/{{row.id}}">{{row.venue}}</a></td>
                </ng-container>

                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef width="10%"> Typ </th>
                  <td mat-cell *matCellDef="let row"> {{row.type}} </td>
                </ng-container>
                
                <ng-container matColumnDef="visible">
                  <th mat-header-cell *matHeaderCellDef> Visible </th>
                  <td mat-cell *matCellDef="let row"><mat-icon *ngIf="row.visible == false" class="red">visibility_off</mat-icon></td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="locationsTableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: locationsTableColumns;"></tr>
            
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter</td>
                </tr>
              </table>
















            </div>
          </div></div>
        </mat-tab>
      </mat-tab-group>
</div>