<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
            <h1>Dashboard</h1>
        </div>
    </div> 
    <div class="row">
        <div class="col-xs-12">
            <div class="card">
               <img src="./../../../assets/dashboard.svg"><h2>Willkommen</h2>
            </div>
        </div>
    </div>   
    <div class="row">        
        <div class="col-xs-6">
            <a routerLink="/bookings/"><div class="card">
                <img src="./../../../assets/bookings.svg" width="48px"><h2>Alle Buchungen anzeigen</h2>
            </div></a>
        </div>
        <div class="col-xs-6">
            <a routerLink="/content/"><div class="card">
                <img src="./../../../assets/content.svg" width="48px"><h2>Daten bearbeiten</h2>
            </div></a>
        </div>
    </div>   
</div>