<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
            <h1>Buchungen <span class="smallBadge">{{openBookings?.length}} offen</span><span class="right"><button mat-icon-button aria-label="Buchungen exportieren" (click)="exportBookings()"><mat-icon>download</mat-icon></button></span></h1>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">

            <ng-container *ngFor="let booking of openBookings">

                <a routerLink="/bookings/{{booking.data?.bookingCode}}">
                    <div class="card" [ngClass]="{'new': booking.data.showtime.datetime.seconds < tomorrowSeconds}">
                        <div class="row">
                            <div class="col-xs-12">
                                <h2>{{booking.data.showtime.datetime | date: 'dd.MM.yyyy HH:mm'}}</h2>
                                <ng-container *ngIf="booking.data.showtime.datetime.seconds < tomorrowSeconds">
                                    <mat-chip class="warning">DRINGEND</mat-chip>
                                </ng-container>
                            </div>
                            <div class="col-xs-12">
                                {{booking.data.eventSummary.name}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">Booking Code: <br><strong>{{booking?.data?.bookingCode}}</strong></div>
                            <div class="col-xs-3">ID: <br><strong>{{booking?.id}}</strong></div>
                            <div class="col-xs-2">Typ: <br><strong>{{booking?.data?.eventSummary?.type}}</strong></div>
                            <div class="col-xs-3">Anzahl Tickets: <br><strong>{{booking.data.tickets?.length}}</strong></div>
                        </div>
                    </div>
                </a>
        </ng-container>

            <span class="dateSplit">Abgeschlossene Buchungen</span>

            <ng-container *ngFor="let booking of closedBookings">
                <a routerLink="/bookings/{{booking.data?.bookingCode}}">
                    <div class="card disabled">

                        <div class="row">
                            <div class="col-xs-2">Buchung: <br><strong>{{booking?.data?.bookingCode}}</strong><br><span *ngIf="booking?.data?.date">{{booking?.data?.date | date: 'dd.MM.yyyy | HH:mm'}} Uhr</span></div>
                            <div class="col-xs-2">Event: <br><strong>{{booking?.data?.showtime?.eventName}}</strong><br>{{booking.data.showtime.datetime | date: 'dd.MM.yyyy | HH:mm'}} Uhr</div>
                            <div class="col-xs-2">Location: <br><strong>{{booking?.data?.showtime?.location?.venue}}</strong></div>
                            <div class="col-xs-1">Typ: <br><strong>{{booking?.data?.eventSummary?.type}}</strong></div>
                            <div class="col-xs-2">Anzahl Tickets: <br><strong>{{booking.data.tickets?.length}}</strong></div>
                            <div class="col-xs-3">Mitglied:<br><strong>{{booking?.data?.uid}}</strong></div>
                        </div>
                            <!-- <div class="row">
                                <div class="col-xs-3">{{booking.firstName}} {{booking.lastName}}</div>
                                <div class="col-xs-6">
                                    <p><strong>{{booking.event.title}}</strong></p>
                                    {{booking.event.datetime.toDate() | date: 'dd.MM.yyyy'}}
                                </div>
                                <div class="col-xs-1 center"> 
                                    <mat-icon>person</mat-icon><br>
                                    {{booking.tickets.length}}
                                </div>                                
                                <div class="col-xs-2 center">
                                        <ng-container *ngIf="booking.shipping == 0">
                                            <mat-icon>local_shipping</mat-icon><br>
                                            Standard
                                        </ng-container>
                                        <ng-container *ngIf="booking.shipping == 1">
                                            <mat-icon>directions_bike</mat-icon><br>
                                            Express                              
                                        </ng-container>
                                        <ng-container *ngIf="booking.shipping == 2">
                                            <mat-icon>store_mall_directory</mat-icon><br>
                                            Abholung                               
                                        </ng-container>
                                </div>
                            </div> -->
                        </div>
                    </a>                

            </ng-container>


            <!-- <a routerLink="/bookings/123456"><div class="card disabled"><div class="row">
                <div class="col-xs-2">#123456</div>
                <div class="col-xs-5">Event:<br><strong>Britney Spears World Tour</strong></div>
                <div class="col-xs-5"><a href="">Hans.wurst@myeventpass.com</a><br><strong>5 Personen</strong></div>
            </div></div></a>
            <a routerLink="/bookings/123456"><div class="card disabled"><div class="row">
                <div class="col-xs-2">#123456</div>
                <div class="col-xs-5">Event:<br><strong>Britney Spears World Tour</strong><br>EVENT DATE [DRINGEND] </div>
                <div class="col-xs-5"><a href="">Hans.wurst@myeventpass.com</a><br><strong>5 Personen</strong></div>
            </div></div></a> -->
        </div>
        <div class="col-xs-12 col-md-3">
            <!-- <mat-calendar [selected]="visitDate" (selectedChange)="selectedChange($event)" [maxDate]="maxDate" class="calendarBox"></mat-calendar>         -->
            <!-- <mat-calendar class="calendarBox"></mat-calendar>         -->
        </div>
    </div>
</div>


