import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service'
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(3)])
  });

  errorMessage: string = '';
  returnUrl: string = '/';
  hide:boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }


  login(value:any){
    this.authService.signInEmail(value)
    .then(res => {
      console.log(res);          console.log(res.user.uid);

      this.router.navigate([this.returnUrl]);
    }, err => {
      console.log(err);
      this.errorMessage = err.message;
    })
  }
  
}