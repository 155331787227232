import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingsService } from 'src/app/services/bookings.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  customerId:string = "";
  bookingCode = this.route.snapshot.paramMap.get('id');
  bookingId = "";
  booking: any;
  bookingStatus: boolean = false;
  selectedFiles: Array<any> =[];
  customer:any;
  numberOfTickets: number = 0;
  errorMessage: string = ""; 

  display: FormControl = new FormControl("", Validators.required);

  file_store: FileList | undefined;
  file_list: Array<string> = [];

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    // private clipboard: Clipboard,
    private bookingService: BookingsService
  ) { 
  }
  
  copyShippingAddress() {
    // let shippingAddress = this.booking.shippingAddress.firstname+" "+this.booking.shippingAddress.lastName
    // this.clipboard.writeText(shippingAddress);
    alert("Adresse kopiert")
  }

  getBooking() {
    this.bookingService.getBooking(this.bookingCode).then((result) => {
      this.booking = result.data();
      this.customerId = this.booking.uid;
      this.bookingId = result.id;
      this.numberOfTickets = this.booking.tickets.length;
      console.log(this.booking);
    });
  }  

  ngOnInit(): void {
    this.getBooking();
  }

  toggleStatus(newStatus:any) {
    const dialogRef = this.dialog.open(UpdateStatusComponent, {
      width: '400px',
      data: {status: newStatus.value},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.booking.status = newStatus.value;
        this.bookingService.changeBookingStatus(this.customerId, this.bookingId, newStatus.value).then(result => {
          console.log(result);
          this.router.navigate(["/bookings"]);
        });
      } else {
        this.booking.status = "pending";
      }
    });
  }

  onFileSelected(event:any, ticketNr:string): void {
    this.selectedFiles.push({
      'ticketNr':ticketNr,
      'file':event.target.files[0]
    });
  }

  getCustomer() {
    this.bookingService.getCustomer(this.customerId).then(result => {
      console.log(result);

      // this.customer = result;

    });
  }

  uploadTickets() {
    this.errorMessage = "";
    if(this.selectedFiles.length == this.numberOfTickets) {
      this.selectedFiles.forEach(element => {
        this.bookingService.uploadFileToStorage(this.customerId,this.bookingId,element.ticketNr,element.file);
        this.router.navigate(["/bookings"]);
      });
    } else {
      this.errorMessage = "Jedes Ticket benötigt ein PDF";
    }

  }

}





@Component({
  selector: 'app-update-status',
  templateUrl: './update-status.component.html',
  styleUrls: ['./update-status.component.scss']
})
export class UpdateStatusComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<UpdateStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

}