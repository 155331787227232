import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private db: AngularFirestore,
    private fireFunctions: AngularFireFunctions,
  ) { }

  getLocations(): Observable<any> {
    return this.db.collection('locations')
    // return this.db.collection('locations', ref => ref.where("manuallyUpdated", "!=", "undefined"))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map((a:any) => {
        return { 
          id: a.payload.doc.id, 
          type: a.payload.doc.data().type, 
          venue: a.payload.doc.data().venue,
          geolocation: a.payload.doc.data().geolocation,
          visible: a.payload.doc.data().visible
        };      
      }))
    );
  }

  getLocationById(document:string) {

    // return new Promise<any>((resolve, reject) => {
    //   this.db
    //   .collection("locations")
    //   .doc(document)
    //   .get().pipe(map(action => {
    //       const data = action.data(); 
    //       const id = action.id;    
    //       return { id, data };   
    //     }));
    // });

    return this.db
    .collection("locations")
    .doc(document)
    .get().pipe(map(action => {
      const data = action.data(); 
      const id = action.id;    
      return { id, data };   
    }));
  }

  updateLocation (documentId:string, updateValue:any): Observable<any> {
    const manualEntityUpdate = this.fireFunctions.httpsCallable('manualEntityUpdate');
    return manualEntityUpdate({
      type:"location",
      id: documentId,
      updates: updateValue
    });
  }

}
