<a routerLink="/login" class="logo">My Event Pass</a><br> 

<div class="floatingTile">

    <h1  class="center">Login</h1>
    <div *ngIf="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
    <form [formGroup]="loginForm">
        <mat-form-field class="full-width" appearance="outline">
            <mat-label >E-Mail</mat-label>
            <input matInput type="email" placeholder="mail@domain.com" formControlName="email" tabindex="1" min-lenght="4" #email>
        </mat-form-field> 
        <mat-form-field class="full-width" appearance="outline">
            <mat-label >Passwort</mat-label>
            <input matInput placeholder="Enter your password" [type]="hide ? 'text' : 'password'" tabindex="2" formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <button mat-raised-button color="primary" class="full-width" (click)="login(loginForm?.value)" tabindex="3">Login</button>
    </form>
    
</div>
