import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { environment } from 'src/environments/environment';
import mapboxgl from 'mapbox-gl';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss']
})
export class LocationDetailComponent {
  locationId = this.route.snapshot.paramMap.get('id');
  location:any;
  locationDetailForm : UntypedFormGroup;
  visibility:string = "visibility_on";
  private map: mapboxgl.Map;
  // @ViewChild("mapContainer") mapContainer:ElementRef;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private _snackBar: MatSnackBar
  ) {
    if(this.locationId) {
      this.getLocation(this.locationId);
    }
    this.locationDetailForm = this.formBuilder.group({
      venue: [{value:''}, Validators.required],
      type: [{value:''}, Validators.required],
    });

  }

  getLocation(locationId:string) {
    this.locationService.getLocationById(locationId).subscribe((result:any) => {
      this.location = result;    
      if(this.location.data.visible == false) {
        this.visibility = "visibility_off";
      }
      this.locationDetailForm.patchValue(result.data);
    });
  }



   initMap(location:any):void {

    // Init map
    this.map = new mapboxgl.Map({
      container: "mapContainer",
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 12,
      center: [location.data.geolocation.long,location.data.geolocation.lat],
      accessToken: environment.mapbox.accessToken,
    });
   

    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());


    // Add marker
    const locationMarker = new mapboxgl.Marker({
      draggable: true
      })
    .setLngLat([location.data.geolocation.long,location.data.geolocation.lat])
    .on('dragend', () => {
      this.onDragEnd(locationMarker);
    })
    .addTo(this.map);
  }



   onDragEnd(locationMarker:any) {

    if(this.locationId) {

      const lngLat = locationMarker.getLngLat();

      const lng = lngLat.lng.toString();
      const lat = lngLat.lat.toString();


      this.locationService.updateLocation(this.locationId, {'geolocation':{lat:lat,long:lng}}).subscribe(result => {
        this._snackBar.open("Location wurde gespeichert", 'schließen', {
          duration: 3000
        });
      });

    }
  }



  checkForUpdate(attribute:string): boolean {
    if(!this.location?.data?.manuallyUpdated) {return false}
    const manuallyUpdatedAttribute = this.location?.data?.manuallyUpdated.find((x:any) => x.property == attribute);
    if(manuallyUpdatedAttribute) {
      return true;
    }
    return false;
  }

  switchVisibility(eventId:string, visibility:string) {
    this.visibility = visibility;
    if(visibility == "visibility_on") {
      this.locationService.updateLocation(
        eventId, {'visible':true}
      ).subscribe(result => {});
    } else {
      this.locationService.updateLocation(
        eventId, {'visible':false}
      ).subscribe(result => {});
    }
  }

  updateLocation(locationId:string) {
      var formRawValue = this.locationDetailForm.getRawValue();
      if (!this.locationDetailForm.valid) return

      if(this.location.data.venue != formRawValue.venue) {
        this.locationService.updateLocation(
          locationId, {'venue':formRawValue.venue}
        ).subscribe(result => {
          });
      }

      if(this.location.data.type != formRawValue.type) {
        this.locationService.updateLocation(
          locationId, {'type':formRawValue.type}
        ).subscribe(result => {
        });
      }

     

      this._snackBar.open("Location wurde gespeichert", 'schließen', {
        duration: 3000
      });


    }



}
