import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { MainpageComponent } from './layouts/mainpage/mainpage.component';
import { SubpageComponent } from './layouts/subpage/subpage.component';
import { ModalpageComponent } from './layouts/modalpage/modalpage.component';
import { MainnavigationComponent } from './components/navigation/mainnavigation/mainnavigation.component';
import { SubnavigationComponent } from './components/navigation/subnavigation/subnavigation.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { BookingsComponent } from './views/bookings/bookings.component';
import { DetailComponent, UpdateStatusComponent } from './views/bookings/detail/detail.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SignOutComponent } from './views/sign-out/sign-out.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';;
import { environment } from '../environments/environment';
import { AuthGuard } from './guard/auth.guard';
import { AuthService } from './services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
// import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatSelectModule} from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { ContentListComponent } from './views/content/content-list/content-list.component';
import { EventDetailComponent } from './views/content/event-detail/event-detail.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LocationDetailComponent } from './views/content/location-detail/location-detail.component';
import { StatsComponent } from './views/stats/stats.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserListComponent } from './views/users/user-list/user-list.component';

registerLocaleData(localeDe, 'de-DE');
const dateFormat = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    MainpageComponent,
    SubpageComponent,
    ModalpageComponent,
    MainnavigationComponent,
    SubnavigationComponent,
    DashboardComponent,
    BookingsComponent,
    DetailComponent,
    SignOutComponent,
    UpdateStatusComponent,
    // ExportBookingsComponent,
    ContentListComponent,
    EventDetailComponent,
    LocationDetailComponent,
    StatsComponent,
    UserListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatChipsModule,
    MatSortModule,
    // ClipboardModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule
  ],
  providers: [
    AuthService, 
    AuthGuard,
    { provide: REGION, useValue: 'europe-west1' },
    { provide: MAT_DATE_FORMATS, useValue: dateFormat }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
