<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
            <h1>Statistiken</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="box">
            <h2>Abgeschlossene Buchungen</h2>

            <table width="100%" class="exportTable">
                <tr>
                    <th style="text-align: left;">Buchungs-ID</th>
                    <th style="text-align: left;">Buchungsdatum</th>
                    <th style="text-align: left;">Event-ID</th>
                    <th style="text-align: left;">Eventdatum</th>
                    <th style="text-align: left;">Location</th>
                    <th style="text-align: left;">Anzahl&nbsp;Tickets</th>
                    <th style="text-align: left;">Preis</th>
                    <th style="text-align: left;">Eingelöste&nbsp;Credits</th>
                </tr>

                <ng-container *ngFor="let booking of closedBookings; let i = index">
                    <tr>
                        <td>{{booking?.id}}</td>
                        <td>{{booking?.data?.date | date: 'dd.MM.yyyy | HH:mm'}}</td>
                        <td>{{booking?.data?.eventId}}</td>
                        <td>{{booking?.data?.showtime?.datetime | date: 'dd.MM.yyyy'}}</td>
                        <td>{{booking?.data?.showtime?.location?.venue}}</td>
                        <td>{{booking?.data?.tickets.length}}</td>
                        <td><ng-container *ngFor="let ticket of booking?.data?.tickets">
                            <ng-container *ngIf="ticket?.priceCategory?.amount">{{ticket?.priceCategory?.amount | number: '1.2':'de-DE'}} €<br></ng-container>
                            <ng-container *ngIf="ticket?.channelResult?.cartDetails?.priceInfo?.totalPrice?.value">{{ticket?.channelResult?.cartDetails?.priceInfo?.totalPrice?.value | number: '1.2':'de-DE'}} €<br></ng-container>
                        </ng-container></td>
                        <td>{{booking?.data?.spendFromWalletResult?.totalSpentCoinsAmount}}</td>
                    </tr>
                </ng-container>

            </table>
           
            </div>
        </div>
    </div>
</div>