import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private db: AngularFirestore,
    private fireFunctions: AngularFireFunctions,
  ) { }

//  ############################################################
//  NUR FÜR CLEANUP
//  ############################################################
    // deleteFile(documentId:string) {
    //   console.log(documentId);
    //   this.db.collection('events').doc(documentId).delete();
    // }

    getAllShowtimes() {
      return this.db.collectionGroup("showtimes", ref => ref.limit(150))
        .snapshotChanges()
        .pipe(
          map(actions => actions.map((action:any) => {
            const id = action.payload.doc.ref.id;
            const parentid = action.payload.doc.ref.parent.parent.id;
              return {id, parentid};
            }))
        )
    }

    // deleteSubCollectionFile(documentId:string, subDocumentId:string) {
    //   this.db.collection('events').doc(documentId).collection('showtimes').doc(subDocumentId).delete();
    // }


    // updatemanuallyupdate (array:any, id:string) {
    //   this.db
    //   .collection("events")
    //   .doc(id)
    //   .update({ 
    //     "manuallyUpdated" : array
    //   })
     
    // }


    // assignCoins(userId:string): Observable<any> {
    //   const manualCoinsTransaction = this.fireFunctions.httpsCallable('assignCoins');
    //   return manualCoinsTransaction({
    //     customerId:userId,
    //     coins: 22,
    //     description: "Credits aus Kulanz von Helpdesk"
    //   });
    // }


//  ############################################################



  getEvents(limit?:number): Observable<any> {
    return this.db.collection('events')
    .snapshotChanges()
    .pipe(
      map(actions => actions.map((a:any) => {
        // if (a.payload.doc.data().manuallyUpdated?.length > 0) {
        //   console.log(a.payload.doc.id, a.payload.doc.data().manuallyUpdated.length);
        // }
        return { 
          id: a.payload.doc.id, 
          type: a.payload.doc.data().type, 
          name: a.payload.doc.data().name,
          mostRecentShowtime: a.payload.doc.data().mostRecentShowtime, 
          visibleNewAt: a.payload.doc.data().visibleNewAt, 
          popularity: a.payload.doc.data().popularity,
          visible: a.payload.doc.data().visible,
          manuallyUpdated: a.payload.doc.data().manuallyUpdated
        };      
      }))
    );
  }

  getEventById(document:string) {
    return this.db
    .collection("events")
    .doc(document)
    .get().pipe(map(action => {
      const data = action.data(); 
      const id = action.id;    
      return { id, data };   
    }));
  }


  getShowtimes(document:string) {
    return this.db
    .collection("events")
    .doc(document)
    .collection("showtimes")
    .snapshotChanges()
    .pipe(take(1),
      map(actions => actions.map((a:any) => {
        return { 
          id: a.payload.doc.id,
          datetime: a.payload.doc.data().datetime,
          status: a.payload.doc.data().status,
          visible: a.payload.doc.data().visible,
          priceCategories: a.payload.doc.data().priceCategories
        };      
      }))
    );
  }









  updateEvent (documentId:string, updateValue:any): Observable<any> {
    const manualEntityUpdate = this.fireFunctions.httpsCallable('manualEntityUpdate');
    return manualEntityUpdate({
      type:"event",
      id: documentId,
      updates: updateValue
    });
  }
  
  async updatePriceCategory (eventId:any, showtimeId:string,  priceCategories:any, hasSpecialPrice:boolean) {
    const docRef = this.db.collection("events").doc(eventId);
    await docRef.update({ 
      "hasSpecialPrice" : hasSpecialPrice
    })
    // .then(writeResult => {
    //   console.log(writeResult);
    //   return docRef.get();
    // }).then(documentSnapshot => {
    //   console.log(documentSnapshot); 
    // })


    // this.db
    // .collection("events")
    // .doc(eventId)
    await docRef.collection("showtimes")
    .doc(showtimeId)
    .update({ 
      "priceCategories" : priceCategories,
      "hasSpecialPrice" : hasSpecialPrice
 }).then(documentSnapshot => {
  return true
    })
  }

}
