import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent {

  eventId = this.route.snapshot.paramMap.get('id');
  event:any;
  showtimes:Array<any> = [];
  eventDetailForm : UntypedFormGroup;
  priceCategoryForm : UntypedFormGroup;
  visibility:string = "visibility_on";
  loadingState:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private _snackBar: MatSnackBar
  ) {

    if(this.eventId) {
      this.getEvent(this.eventId);
      this.getShowtimes(this.eventId);
    }
    this.eventDetailForm = this.formBuilder.group({
      name: [{value:''}, Validators.required],
      thumbnail: [''],
      type: [{value:''}, Validators.required],
      popularity: [Number],
      description: [''],
    });
    this.priceCategoryForm = this.formBuilder.group({
      coins: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

 
  getEvent(eventId:string) {
    this.eventService.getEventById(eventId).subscribe((result:any) => {
      this.event = result;
      if(this.event.data.visible == false) {
        this.visibility = "visibility_off";
      }
      this.eventDetailForm.patchValue(result.data);
    });
  }

  getShowtimes(eventId:string) {
    this.showtimes = [];
    this.eventService.getShowtimes(eventId).subscribe((result:any) => {
      this.showtimes = result;
    });
  }

  checkForUpdate(attribute:string): boolean {
    if(!this.event?.data?.manuallyUpdated) {return false}
    const manuallyUpdatedAttribute = this.event?.data?.manuallyUpdated.find((x:any) => x.property == attribute);
    if(manuallyUpdatedAttribute) {
      return true;
    }
    return false;
  }

  switchVisibility(eventId:string, visibility:string) {
    this.visibility = visibility;
    if(visibility == "visibility_on") {
      this.eventService.updateEvent(
        eventId, {'visible':true}
      ).subscribe(result => {});
    } else {
      this.eventService.updateEvent(
        eventId, {'visible':false}
      ).subscribe(result => {});
    }
  }

  updateEvent(eventId:string) {
      var updateList = {};
      var formRawValue = this.eventDetailForm.getRawValue();
      if (!this.eventDetailForm.valid) return

      if(this.event.data.name != formRawValue.name) {
        Object.assign(updateList, {'name':formRawValue.name});
      }

      if(this.event.data.type != formRawValue.type) {
        Object.assign(updateList, {'type':formRawValue.type});
      }

      if(this.event.data.popularity != formRawValue.popularity) {
        var popularityNumber:Number = new Number(formRawValue.popularity);
        Object.assign(updateList, {'popularity':popularityNumber});
      }

      if(this.event.data.thumbnail != formRawValue.thumbnail) {
        Object.assign(updateList, {'thumbnail':formRawValue.thumbnail});
      }

      if(this.event.data.description != formRawValue.description) {
        Object.assign(updateList, {'description':formRawValue.description});
      }

      this.eventService.updateEvent(
        eventId, updateList
      ).subscribe(result => {
        this._snackBar.open("Event wurde gespeichert", 'schließen', {
          duration: 3000
        });
      });
    }


    updatePriceCategory(showtime:any, priceCategoryId:number, priceCategory:any) {
      this.loadingState = true;
      var formRawValue = this.priceCategoryForm.getRawValue();
      let tempPriceCategories = showtime.priceCategories;
      let specialPriceCoins:number = formRawValue.coins;
      tempPriceCategories[priceCategoryId] = {
          "amount": priceCategory.amount,
          "category": priceCategory.category,
          "channel": priceCategory.channel,
          "coins": priceCategory.coins,
          "currency": priceCategory.currency,
          "inventory": priceCategory.inventory,
          "name": priceCategory.name,
          "specialPrice": {
            "coins": specialPriceCoins,
            "description": formRawValue.description
          }
      }
      this.eventService.updatePriceCategory(this.eventId,showtime.id,tempPriceCategories,true).then(response => {
        this.event = {};
        this.showtimes = [];
        if(this.eventId) {
          this.getEvent(this.eventId);
          this.getShowtimes(this.eventId);
        }
        this.loadingState = false;    
      });
    }

    removeSpecialPrice(showtime:any, priceCategoryId:number, priceCategory:any) {
      this.loadingState = true;
      let tempPriceCategories = showtime.priceCategories;
      tempPriceCategories[priceCategoryId] = {
          "amount": priceCategory.amount,
          "category": priceCategory.category,
          "channel": priceCategory.channel,
          "coins": priceCategory.coins,
          "currency": priceCategory.currency,
          "inventory": priceCategory.inventory,
          "name": priceCategory.name
      }
      this.eventService.updatePriceCategory(this.eventId,showtime.id,tempPriceCategories,false).then(result => {
        this.loadingState = false;    
      });
    }

}
